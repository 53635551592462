/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hrtext {
  overflow: hidden;
  text-align: center;
}

.hrtext:before,
.hrtext:after {
  background-color: rgb(192, 192, 192);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.hrtext:before {
  right: 0.5em;
  margin-left: -50%;
}

.hrtext:after {
  left: 0.5em;
  margin-right: -50%;
}

.btn:focus,.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.text-bold{
  font-weight: bold;
}